.gridpie {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.gridnumber {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  justify-items: center;
}

.bigPieBar {
  display: grid;
  grid-template-columns: 20% 80%;
  justify-items: center;
}

.gridlinear {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: "center";
  justify-items: "center";
  padding: 1em 0em 1em 0em;
}

.gridzone {
  display: grid;
  grid-template-columns: 100%;
  align-items: "center";
  justify-items: "center";
  padding: 0em 0em 0em 0em;
}

.gridline {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: "center";
  justify-items: "center";
  padding: 1em 0em 1em 0em;
}

.linesCharts {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: "center";
  justify-items: "center";
  padding: 1em 0em 1em 0em;
}

.Dividers {
  display: none;
}

.BigDistri {
  width: 25%;
  padding: 0em 1em 1em 1em;
}

.lineChart {
  min-height:350px;
}

@media screen and (max-width: 2000px) {
  .gridzone {
    grid-template-columns: 100%;
  }
  .gridline {
    grid-template-columns: 50% 50%;
  }
  .bigPieBar {
    grid-template-columns: 20% 80%;
  }
  .linesCharts {
    grid-template-columns: 50% 50%;
  }
  .BigDistri {
    width: 30%;
    padding: 0em 1em 1em 1em;
  }
  .secondary{
    
  }
}

@media screen and (max-width: 1600px) {
  .gridzone {
    grid-template-columns: 50% 50%;
  }
  .gridline {
    grid-template-columns: 50% 50%;
  }
  .bigPieBar {
    grid-template-columns: 100%;
  }
  .linesCharts {
    grid-template-columns: 100%;
  }
  .BigDistri {
    width: 100%;
    padding: 0em 1em 1em 1em;
  }
  .secondary{

  }
  .lineChart {
    min-height:300px;
  }
}

@media screen and (max-width: 1400px) {
  .gridpie {
    grid-template-columns: 25% 25% 25% 25%;
  }
  .gridlinear {
    grid-template-columns: 100%;
  }
  .gridnumber {
    grid-template-columns: 25% 25% 25% 25%;
  }
  .gridzone {
    grid-template-columns: 50% 50%;
  }
  .gridline {
    grid-template-columns: 50% 50%;
  }
  .bigPieBar {
    grid-template-columns: 100%;
  }
  .linesCharts {
    grid-template-columns: 100%;
  }
  .lineChart {
    min-height:200px;
  }
}

@media screen and (max-width: 1200px) {
  .gridpie {
    grid-template-columns: 50% 50%;
  }
  .gridlinear {
    grid-template-columns: 100%;
  }
  .gridnumber {
    grid-template-columns: 50% 50%;
  }
  .gridzone {
    grid-template-columns: 50% 50%;
  }
  .gridline {
    grid-template-columns: 50% 50%;
  }
  .bigPieBar {
    grid-template-columns: 100%;
  }
  .lineChart {
    min-height:0;
  }
}

@media screen and (max-width: 1150px) {
  .gridnumber {
    grid-template-columns: 25% 25% 25% 25%;
  }
  .gridzone {
    grid-template-columns: 100%;
  }
  .gridline {
    grid-template-columns: 100%;
  }
  .lineChart {
    min-height:0 ;
  }
}

@media screen and (max-width: 900px) {
  .gridpie {
    grid-template-columns: 100%;
  }
  .gridnumber {
    grid-template-columns: 50% 50%;
  }
  .gridzone {
    grid-template-columns: 100%;
  }
  .gridline {
    grid-template-columns: 100%;
  }
  .lineChart {
    min-height:200px ;
  }
}
